<template>
    <div>
        <el-main>
            <el-button type="primary" style="margin-bottom: 10px" size="small"
                @click="$router.push({ path: '/marketing/repairPurchaseAdd' })">+添加采购</el-button>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="商品信息：">
                    <el-input size="small" placeholder="请输入商品名称" v-model="searchForm.title"></el-input>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="consumer_card_picture" label="商品信息" align="center" min-width="280">
                    <template v-slot="{ row }">
                        <div style="display: flex;align-items: center;">
                            <el-image :src="row.goods_image" alt=""
                                style="width: 60px;height: 60px;margin-right:10px;flex-shrink: 0;"></el-image>
                            <div style="text-align: left;">{{ row.goods_title }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="goods_price" label="价格" align="center" width="130"></el-table-column>
                <el-table-column label="第一阶起批量" align="center" width="180">
                    <template v-slot="{ row }">
                        {{ row.ladder_price_info[0].start }}~{{ row.ladder_price_info[0].end }}
                    </template>
                </el-table-column>
                <el-table-column label="批发价格" align="center" width="130">
                    <template v-slot="{ row }">
                        {{ row.is_mixedbatchh ? '--' : row.ladder_price_info[0].price }}
                    </template>
                </el-table-column>
                <el-table-column label="第二阶起批量" align="center" width="180">
                    <template v-slot="{ row }">
                        {{ row.ladder_price_info[1].start }}~{{ row.ladder_price_info[1].end }}
                    </template>
                </el-table-column>
                <el-table-column label="批发价格" align="center" width="130">
                    <template v-slot="{ row }">
                        {{ row.is_mixedbatchh ? '--' : row.ladder_price_info[1].price }}
                    </template>
                </el-table-column>
                <el-table-column label="第三阶起批量" align="center" width="180">
                    <template v-slot="{ row }">
                        {{ row.ladder_price_info[2].start }}~
                    </template>
                </el-table-column>
                <el-table-column label="批发价格" align="center" width="130">
                    <template v-slot="{ row }">
                        {{ row.is_mixedbatchh ? '--' : row.ladder_price_info[2].price }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="150" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text"
                            @click="$router.push({ path: '/marketing/repairPurchaseEdit', query: { id: scope.row.id } })"
                            size="small">编辑</el-button>
                        <el-button type="text" @click="handleDel(scope.row.id)" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
    </div>
</template>
  
<script>

import _ from 'lodash';
import Paging from '@/components/paging';

export default {
    components: {
        Paging
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                title: '',
            }
        };
    },
    created () {
        this.getList();
    },
    methods: {
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                title: '',
            };
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        // 获取列表
        getList () {
            let searchForm = { ...this.searchForm }
            this.$axios.post(this.$api.repair.marketing.purchaseList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        changeIsUp (row) {
            this.$axios.post(this.$api.repair.marketing.ConsumerCardSetStatus, {
                id: row.id,
                status: row.status
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('编辑成功');
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleDel (id) {
            this.$confirm('确定要删除此数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios.post(this.$api.repair.marketing.purchaseGoodDel, { id }).then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
        }
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}

.detail {
    width: 600px;

    /deep/img {
        width: 100%;
        height: auto;
    }
}
</style>
  